<template>
    <div class="row-item promise-row">

        <!-- Status promise -->
        <div class="column-icon status-promise" v-bind:class="[
            promise.daily_promise_status, {
                completed: promise.today_promise ? promise.today_promise.status == 'completed' : false,
                not_completed: promise.today_promise ? promise.today_promise.status == 'not_completed' : false,
                warning: promise.today_promise ? promise.today_promise.status == 'pending' : false
            }]">
            <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf"
                :delay="{ show: 200, hide: 0 }">
                <icon-promise />

                <template slot="popover">
                    <div class="title">{{ $t('meeting_session.status_promise') }}</div>
                    <div class="simple-text" v-if="promise.daily_promise_status === 'person_not_working_today'">{{
                        $t('timeline.promise-not-need') }}</div>
                    <div class="simple-text" v-else-if="promise.daily_promise_status === 'promise_not_set'">{{
                        $t('timeline.promise-not-set') }}</div>
                    <div class="simple-text"
                        v-else-if="promise.daily_promise_status === 'has_promise' && promise.today_promise.status == 'completed'">
                        {{ $t('promise_objectives.status.completed') }}</div>
                    <div class="simple-text"
                        v-else-if="promise.daily_promise_status === 'has_promise' && promise.today_promise.status == 'not_completed'">
                        {{ $t('promise_objectives.status.not_completed') }}</div>
                    <div class="simple-text"
                        v-else-if="promise.daily_promise_status === 'has_promise' && promise.today_promise.status == 'pending'">
                        {{ $t('promise_objectives.status.pending') }}</div>
                </template>
            </v-popover>
        </div>

        <!-- Name -->
        <div class="column-input">
            <input type="text" :placeholder="$t('meeting_session.write_promise')" v-model="titlePromise"
                :disabled="promise.daily_promise_status != 'promise_not_set'">
        </div>
        
        <!-- Choose key result -->
        <div class="column-select" :class="{ 'only-read': promise.daily_promise_status != 'promise_not_set' }">
            <multiselect class="hide-tags multiselect_checkbox" 
                v-model="selectedKeyResult"
                :options="promise.user_key_results" 
                :allow-empty="true" 
                :show-labels="false" 
                :close-on-select="false"
                :multiple="true" 
                track-by="id" 
                label="name"
                @input="checkErrorData"
                v-if="promise.daily_promise_status === 'promise_not_set'">
                <template slot="placeholder" slot-scope="props">
                    <span class="text">
                        {{ $t('meeting_session.choose_key_result') }}
                    </span>
                </template>
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name
                    }}</span>
                    <span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }}
                        {{ $t('filters.selected') }}</span>
                </template>
                <template slot="option" slot-scope="props">
                    <div class="checkbox">
                        <div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
                    </div>
                </template>
                <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
            </multiselect>
            <template v-else>
                <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf"
                    :delay="{ show: 200, hide: 0 }" v-if="promise.today_promise">
                    <div class="text">
                        <span class="label">{{ $t('evaluate_promise.contribute_to') }}</span>
                        <span class="count">{{ $tc('dashboard.count_key_results_involved',
                            promise.today_promise.key_results_names.length) }}</span>
                    </div>
                    <template slot="popover">
                        <div class="simple-text">
                            <p v-for="key_result in promise.today_promise.key_results_names">- {{ key_result }}</p>
                        </div>
                    </template>
                </v-popover>
            </template>
        </div>

        <!-- buton -->
        <div class="column-button set-promise" v-if="promise.daily_promise_status === 'promise_not_set'">
            <button class="btn-tbf green" @click="storePromise">
                <div class="text">{{ $t('meeting_session.set')}}</div>
            </button>
        </div>

        <div class="row-info-action error" v-if="errorData">
            <div class="text">{{ $t('meeting_session.choose_kr_promise') }}</div>
        </div>
        <div class="row-info-action" v-else-if="titlePromise">
            <div class="text">{{ $t('meeting_session.remainder_add_promise') }}</div>
        </div>
    </div>
</template>

<script>
import IconPromise from '@/components/Icons/Promise'
import IconPlus from '@/components/Icons/Plus'

export default {
    name: 'RowPromise',
    props: {
        dataPromises: {
            type: Object,
            required: true
        },
        userId: {
            type: Number,
            required: true
        }
    },
    components: {
        IconPromise,
        IconPlus
    },
    computed: {
        promise() {
            return this.dataPromises[this.userId];
        },
    },
    watch: {
        dataPromises: {
            handler(newVal) {
                this.titlePromise = this.promise.today_promise ? this.promise.today_promise.name : '';
            },
            deep: true
        }
    },
    data() {
        return {
            status: 'pending',
            titlePromise: '',
            selectedKeyResult: '',
            errorData: false
        }
    },
    created() {
        this.titlePromise = this.promise.today_promise ? this.promise.today_promise.name : '';
    },
    methods: {
        storePromise() {
            if (this.selectedKeyResult.length > 0 && this.titlePromise.length > 0) {
                this.errorData = false;

                this.$emit('store-promise', {
                    title: this.titlePromise,
                    key_results: this.selectedKeyResult
                });
                
                this.titlePromise = '';
                this.selectedKeyResult = '';
            } else {
                this.errorData = true;
            }
        },
        checkErrorData() {
            if(this.selectedKeyResult.length) {
                this.errorData = false;
            } else {
                this.errorData = true;
            }
        }
    }
}
</script>